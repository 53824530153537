import {
  PAGE_NAME_CINEMA,
  PAGE_NAME_VESTA_SE_PLACEHOLDER,
  PAGE_NAME_VESTA_SW,
  PAGE_NAME_VESTA_SW_PLACEHOLDER,
  PAGE_NAME_FAW_PLACEHOLDER,
  PAGE_NAME_LARGUS,
  PAGE_NAME_AURA,
} from "@/data/constants";
import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import CinemaView from "../views/CinemaView.vue";
import FAWPlaceholderView from "../views/FAWPlaceholderView.vue";
import AuraView from "../views/AuraView.vue";
import LargusView from "../views/LargusView.vue";
import VestaSEPlaceholderView from "../views/VestaSEPlaceholderView.vue";
import VestaSWView from "../views/VestaSWView.vue";
import VestaSWPlaceholderView from "../views/VestaSWPlaceholderView.vue";
import {
  gtmAuraInterior,
  gtmAuraExterior,
  gtmVestaSWCrossExterior,
  gtmVestaSWCrossInterior,
} from "@/utils/gtm";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/cinema",
    name: PAGE_NAME_CINEMA,
    component: CinemaView,
  },
  {
    path: "/largus",
    name: PAGE_NAME_LARGUS,
    component: LargusView,
  },
  {
    path: "/aura",
    name: PAGE_NAME_AURA,
    component: AuraView,
  },
  {
    path: "/vesta-sedan-placeholder",
    name: PAGE_NAME_VESTA_SE_PLACEHOLDER,
    component: VestaSEPlaceholderView,
  },
  {
    path: "/vesta-sw-cross",
    name: PAGE_NAME_VESTA_SW,
    component: VestaSWView,
  },
  {
    path: "/vesta-sw-cross-placeholder",
    name: PAGE_NAME_VESTA_SW_PLACEHOLDER,
    component: VestaSWPlaceholderView,
  },
  {
    path: "/faw-placeholder",
    name: PAGE_NAME_FAW_PLACEHOLDER,
    component: FAWPlaceholderView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// if (process.env.NODE_ENV === "development" || store.state.isMobile) {
// if (process.env.NODE_ENV === "development") {
/**
 * Глобальная переменная window.pageName используется в связке с библиотекой panolens.js
 * для приостановки неактивных в данный момент панорам
 *
 * Процесс инициализации ресурсоемкий(в частности на мобильных устройствах),
 * поэтому панорамы специально не рушатся и не реинициализируются
 */
router.afterEach((to, from) => {
  const { name, query } = to;
  const { mode } = query;

  if (name === "home") {
    window.pageName = "main";
  } else if (mode === "interior") {
    window.pageName = "interior-" + name;
  } else {
    window.pageName = null;
  }

  onChangeCarMode(to, from);
});
// }

function onChangeCarMode(to, from) {
  if (to.name === from.name) {
    const { mode } = to.query;

    gtmEvents(to.name, mode);
  }
}

function gtmEvents(name, mode) {
  if (name === PAGE_NAME_AURA) {
    if (mode === "interior") {
      gtmAuraInterior();
    } else if (mode === "exterior") {
      gtmAuraExterior();
    }
  } else if (name === PAGE_NAME_VESTA_SW) {
    if (mode === "interior") {
      gtmVestaSWCrossInterior();
    } else if (mode === "exterior") {
      gtmVestaSWCrossExterior();
    }
  }
}

export default router;
